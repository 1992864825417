import React from 'react';
import './App.css';
import Header from '@components/common/Header';
import PublicRouter from './router';
import Footer from '@components/common/Footer';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <>
      <main>
        <div id="contents">
          <Header />
          <PublicRouter />
          <Footer />
        </div>

        <div id="modal" />
      </main>
    </>
  );
}

export default App;
