import useLocalStorage from 'react-use-localstorage';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilState, useRecoilValue } from 'recoil';
import { accountInfoAtom, isCorrectNetworkAtom, isLoginAtom } from '@recoil/account';
import { toast, ToastContainer } from 'react-toastify';
import Web3 from 'web3';
import { routes } from '@constants/routes';
import useModal from '@hooks/useModal';
import InfoModal from '@components/common/Modal/claim';
const web3 = new Web3(window.ethereum);
const HomePage = () => {
  const [isMetamaskLogin, setIsMetamaskLogin] = useLocalStorage('isMetamaskLogin', 'false');
  const navigate = useNavigate();
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [isCorrectNetwork, setIsCorrectNetwork] = useRecoilState(isCorrectNetworkAtom);
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);

  const {
    ModalPortal: NotExistMetaMakModal,
    openModal: metamaskOpenModal,
    closeModal: metamaskCloseModal,
  } = useModal();
  const { ModalPortal: MobileModal, openModal: mobileOpenModal, closeModal: mobileCloseModal } = useModal();
  const { ModalPortal: NotChromeModal, openModal: chromeOpenModal, closeModal: chromeCloseModal } = useModal();

  const onLogout = () => {
    setIsMetamaskLogin('false');
    window.location.reload();
  };

  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  const onConnectWallet = async () => {
    if (isMobile()) {
      mobileOpenModal();
      return;
    }

    if (!window.chrome) {
      chromeOpenModal();
      return;
    }

    if (!window.ethereum) {
      metamaskOpenModal();
      return;
    }

    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        });

        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });
        const getChainId = await web3.eth.getChainId();
        console.log(getChainId);

        if (accounts.length && getChainId !== 56) {
          setIsCorrectNetwork(false);
        } else {
          setIsCorrectNetwork(true);
        }

        setAccountInfo(accounts[0]);

        setIsMetamaskLogin('true');
        setIsLogin(true);
        navigate('/locker');
      } catch (err: any) {
        // alert("에러가 발생했습니다.");
        if (err.code === 4902 && isMobile()) {
          alert('Binance Smart Chain 네트워크를 메타마스크에 추가해주세요');
        }

        if (err.code === 4902) {
          alert('Binance Smart Chain 네트워크를 메타마스크에 추가해주세요');
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainName: 'Binance Smart Chain',
                chainId: '0x38',
                nativeCurrency: {
                  name: 'BNB',
                  decimals: 18,
                  symbol: 'BNB',
                },
                rpcUrls: ['https://bscscan.com'],
              },
            ],
          });
        }
      }
    }
  };

  return (
    <>
      <article>
        <section>
          <div className="claim">
            <strong className="tit">My Wallet Address</strong>
            <div className="shadow-box">
              <span>언락 스케쥴에 맞게 프로토콘 토큰을 Claim하세요.</span>
              <p>
                지정된 일정대로 PEN Token은 언락됩니다. <br />
                Claim 버튼을 눌러 Unlock된 토큰을 획득하세요.
              </p>
              {/*{isMobile() && !window.ethereum ? (*/}
              {/*  <a href="https://metamask.app.link/dapp/locker.feefi-dev.com" className="btn-type01" target="_blank">*/}
              {/*    지갑 연결하기*/}
              {/*  </a>*/}
              {/*) : */}
              {!isLogin ? (
                <a className="btn-type01" onClick={onConnectWallet}>
                  지갑 연결하기
                </a>
              ) : (
                <a className="btn-type01" onClick={onLogout}>
                  로그아웃
                </a>
              )}
            </div>
          </div>
        </section>
      </article>
      <ToastContainer />
      {/*<MobileModal>*/}
      {/*  <InfoModal closeModal={mobileCloseModal} desc="PC 브라우저에서 이용부탁드립니다." />*/}
      {/*</MobileModal>*/}

      {/*<NotExistMetaMakModal>*/}
      {/*  <InfoModal closeModal={metamaskCloseModal} desc="메타마스크 크롬 익스텐션을 설치 하신 후 이용하세요." />*/}
      {/*</NotExistMetaMakModal>*/}

      {/*<NotChromeModal>*/}
      {/*  <InfoModal closeModal={chromeCloseModal} desc="크롬 브라우저에서 지원하는 서비스입니다." />*/}
      {/*</NotChromeModal>*/}
    </>
  );
};

export default HomePage;
