import moment from 'moment';
import React from 'react';
import Web3 from 'web3';

export function parsePrice(price: string) {
  if (!price) {
    return ``;
  }

  return `${price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}`;
}
export function commafy(num: string) {
  if (num.includes('.')) {
    return num;
  }

  const str = num.toString().split('.');
  if (str[0].length >= 4) {
    str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
  }
  if (str[1] && str[1].length >= 4) {
    str[1] = str[1].replace(/(\d{3})/g, '$1 ');
  }
  return str.join('.');
}
export const convertCoinAmount = (coin: string) => {
  // const bn = Web3.utils.toWei(coin, 'ether');
  // console.log(bn, 'coin');

  let prefix = '';
  let suffix = '';
  if (coin.includes('.')) {
    const buffer = coin.split('.');
    prefix = parsePrice(buffer[0]);
    suffix = buffer[1].slice(0, 8);
    return prefix + '.' + suffix;
  }

  return parsePrice(coin);
};
const LockerLists = ({
  list,
  setAmount,
  openModal,
  setIdx,
  idx,
}: {
  list: any;
  setAmount: React.Dispatch<React.SetStateAction<string>>;
  openModal: () => void;
  setIdx: React.Dispatch<React.SetStateAction<number>>;
  idx: number;
}) => {
  function convertData(num: string) {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }
  return (
    <>
      <li>
        <div className="left">
          <span>
            {moment(list[0]).format('YYYY.MM.DD')} | <i>{moment(list[0]).format('HH:mm')}</i>
          </span>
        </div>
        <div className="right">
          <strong>
            {commafy(list[3])} &nbsp;
            <span>PEN</span>
          </strong>
          {moment(new Date()).unix() < moment(list[0]).unix() ? (
            <a className="btn-small gray">claim</a>
          ) : !list[4] ? (
            <a
              className="btn-small"
              onClick={() => {
                setAmount(list[3]);
                openModal();
                setIdx(list[5]);
              }}
            >
              Claim
            </a>
          ) : (
            <a className="btn-small gray">Received</a>
          )}
        </div>
      </li>
    </>
  );
};

export default LockerLists;
