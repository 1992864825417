import avatar_img from '@assets/images/img_avatar.png';
import copy_img from '@assets/images/ico_copy.png';
// import useMetamaskAccount from '@hooks/useMetamaskAccount';
import copy from 'copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify';
import useLocalStorage from 'react-use-localstorage';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { accountInfoAtom, isCorrectNetworkAtom, isLoginAtom } from '@recoil/account';
import { lockerContract } from '../../web3Config';
import moment from 'moment';
import LockerLists, { commafy } from '@components/LockerLists';
import useModal from '@hooks/useModal';
import ClaimModal from '@components/common/Modal/claim';
import Web3 from 'web3';
import bigInt from 'big-integer';
import BN from 'bn.js';
const LockerPage = () => {
  const [remainingAmount, setRemainingAmount] = useState('');
  const [receivedAmount, setReceivedAmount] = useState('');
  const [locker, setLocker] = useState<any>([]);
  const [amount, setAmount] = useState('');
  const [idx, setIdx] = useState(-1);
  const isCorrectNetwork = useRecoilValue(isCorrectNetworkAtom);
  const accountInfo = useRecoilValue(accountInfoAtom);
  // const [metaMaskAccount, _] = useMetamaskAccount();
  const [isMetamaskLogin, setIsMetamaskLogin] = useLocalStorage('isMetamaskLogin', 'false');
  const isLogin = useRecoilValue(isLoginAtom);
  const [loading, setLoading] = useState(false);

  const { ModalPortal, openModal, closeModal } = useModal();
  function convertData(num: string) {
    const str = num.toString().split('.');
    if (str[0].length >= 5) {
      str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,');
    }
    if (str[1] && str[1].length >= 5) {
      str[1] = str[1].replace(/(\d{3})/g, '$1 ');
    }
    return str.join('.');
  }
  const navigate = useNavigate();
  const onCopy = (text: string) => () => {
    if (text) {
      copy(text);
      toast('Copied.');
    } else {
      toast('Not Copied.');
    }
  };

  const onClaimHandler = async () => {
    try {
      if (idx > -1 && accountInfo) {
        setLoading(true);
        const result = await lockerContract.methods.claim(idx).send({
          from: accountInfo,
        });
        bootstrap();
        setLoading(false);
        toast('정상적으로 처리 되었습니다.');
        closeModal();
      }
    } catch (err) {
      console.log(err);
      setLoading(false);
      toast.error('에러가 발생했습니다.');
    }
  };

  const bootstrap = async () => {
    if (isLogin && window.ethereum && accountInfo) {
      let remain = 0;
      let receive = 0;
      let test;
      const lockerInfo: any = await lockerContract.methods.getLockInfo(accountInfo).call();
      const copyData = JSON.parse(JSON.stringify(lockerInfo));
      copyData.forEach((item: any, i: number) => {
        if (lockerInfo[i]['released']) {
          receive += Number(lockerInfo[i]['amount']);
        } else {
          // remain += Number(lockerInfo[i]['amount']);
          remain = bigInt(remain).add(bigInt(lockerInfo[i]['amount'])).valueOf();
          test = bigInt(remain).add(bigInt(lockerInfo[i]['amount']));
        }
        const format = moment.utc(Number(item[0]) * 1000).format('YYYY-MM-DD HH:mm:ss');
        item[0] = moment.utc(format).local().format('YYYY-MM-DD HH:mm:ss');
        item[3] = Web3.utils.fromWei(lockerInfo[i]['amount']);
        item[4] = lockerInfo[i]['released'];
        item[5] = i;
      });
      copyData.sort((p: any, n: any) => {
        return moment(p[0]).unix() - moment(n[0]).unix();
      });

      const convertRemain = Web3.utils.fromWei(remain.toLocaleString('fullwide', { useGrouping: false }), 'ether');
      const convertReceive = Web3.utils.fromWei(
        String(receive.toLocaleString('fullwide', { useGrouping: false })),
        'ether'
      );
      setRemainingAmount(convertRemain);
      setReceivedAmount(convertReceive);
      setLocker(copyData);
    }
  };

  const sumToken = (receive: string, remain: string) => {
    console.log(Web3.utils.toWei(receive, 'ether'), Web3.utils.toWei(remain));
    const toWeiReceive = Web3.utils.toWei(receive);
    const toWeiRemain = Web3.utils.toWei(remain);
    console.log(
      bigInt(toWeiReceive).add(toWeiRemain),
      Web3.utils.fromWei(String(bigInt(toWeiReceive).add(toWeiRemain)), 'ether')
    );
    // const sum = bigInt(receive).add(receive);
    // console.log(toWeiReceive, 'sum', toWeiRemain);
    // const sum = Number(toWeiReceive) + Number(toWeiRemain);
    // console.log(Web3.utils.fromWei(String(sum), 'ether'), 'sum');
    //
    return Web3.utils.fromWei(String(bigInt(toWeiReceive).add(toWeiRemain)), 'ether');
  };

  useEffect(() => {
    bootstrap();
  }, [isLogin]);
  remainingAmount && receivedAmount && sumToken(receivedAmount, remainingAmount);
  return (
    <>
      <h3
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          color: '#c92a2a',
          fontWeight: '700',
          marginTop: '20px',
        }}
      >
        {!isCorrectNetwork && isLogin && 'Binance Smart Chain으로 네트워크를 변경해주세요.'}
      </h3>
      <article>
        <section>
          <div className="claim">
            <strong className="tit">My Wallet Address</strong>
            <div className="shadow-box">
              <div className="mytoken">
                {/*<figure>*/}
                {/*  <img src={avatar_img} alt="" />*/}
                {/*</figure>*/}
                <div className="copy">
                  <p>{accountInfo ?? ''}</p>
                  <button onClick={onCopy(accountInfo)}>
                    <img src={copy_img} alt="" />
                  </button>
                </div>
              </div>
              {/*<a href="#" className="btn-type01">*/}
              {/*  전량 Claim 하기*/}
              {/*</a>*/}
            </div>
            <div className="unlock-schedule">
              <strong className="tit">Unlock Schedule</strong>

              {locker.length &&
                locker.map((list: any, i: number) => (
                  <ul className="schedule-list">
                    <LockerLists
                      key={i}
                      list={list}
                      setAmount={setAmount}
                      openModal={openModal}
                      setIdx={setIdx}
                      idx={i}
                    />
                  </ul>
                ))}

              <div className="total">
                <ul>
                  <li className="flex">
                    <em>Total Token</em>
                    {/*// @ts-ignore*/}
                    <strong>
                      {receivedAmount && remainingAmount && commafy(sumToken(remainingAmount, receivedAmount))}
                      &nbsp;<i>PEN</i>
                    </strong>{' '}
                  </li>
                  <li className="flex">
                    <em>Received Token</em>
                    <strong>
                      {commafy(receivedAmount)} <i>PEN</i>
                    </strong>
                  </li>
                  <li className="flex">
                    <em>Locked Token</em>
                    <strong>
                      {commafy(remainingAmount)} <i>PEN</i>
                    </strong>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </article>
      <ToastContainer />
      <ModalPortal>
        <ClaimModal closeModal={closeModal} onClickHandler={onClaimHandler} amount={amount} loading={loading} />
      </ModalPortal>
    </>
  );
};

export default LockerPage;
