import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useLocalStorage from 'react-use-localstorage';
import { useRecoilState } from 'recoil';
import Web3 from 'web3';

import { accountInfoAtom, isCorrectNetworkAtom, isLoginAtom } from '@recoil/account';
import { toast, ToastContainer } from 'react-toastify';
import useModal from '@hooks/useModal';
import InfoModal from '@components/common/Modal/claim';
const web3 = new Web3(window.ethereum);
const Header = () => {
  const [accountInfo, setAccountInfo] = useRecoilState(accountInfoAtom);
  const [isLogin, setIsLogin] = useRecoilState(isLoginAtom);
  const [isMetamaskLogin, setIsMetamaskLogin] = useLocalStorage('isMetamaskLogin', 'false');
  const navigate = useNavigate();
  const [isCorrectNetwork, setIsCorrectNetwork] = useRecoilState(isCorrectNetworkAtom);

  const {
    ModalPortal: NotExistMetaMakModal,
    openModal: metamaskOpenModal,
    closeModal: metamaskCloseModal,
  } = useModal();
  const { ModalPortal: MobileModal, openModal: mobileOpenModal, closeModal: mobileCloseModal } = useModal();
  const { ModalPortal: NotChromeModal, openModal: chromeOpenModal, closeModal: chromeCloseModal } = useModal();

  const onLogout = () => {
    setIsMetamaskLogin('false');
    setIsLogin(false);
    setAccountInfo('');
    window.location.reload();
  };
  function isMobile() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  const onConnectWallet = async () => {
    if (isMobile()) {
      mobileOpenModal();
      return;
    }
    if (!window.chrome) {
      chromeOpenModal();
      return;
    }

    if (!window.ethereum) {
      metamaskOpenModal();
      return;
    }

    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: '0x38' }],
        });

        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });

        const getChainId = await web3.eth.getChainId();

        if (accounts.length && getChainId !== 56) {
          setIsCorrectNetwork(false);
        } else {
          setIsCorrectNetwork(true);
        }

        setAccountInfo(accounts[0]);

        setIsMetamaskLogin('true');
        setIsLogin(true);
        navigate('/locker');
      } catch (err: any) {
        // alert(JSON.stringify(err));
        // alert("에러가 발생했습니다.");
        if (err.code === 4902) {
          alert('Binance Smart Chain 네트워크를 메타마스크에 추가해주세요');
          return;
        }
        alert('에러가 발생했습니다.');
        if (err.code === 4902) {
          console.log('123');
          // await window.ethereum.request({
          //   method: 'wallet_addEthereumChain',
          //   params: [
          //     {
          //       chainName: 'Binance Smart Chain',
          //       chainId: `0x${Number(56).toString(16)}`,
          //       nativeCurrency: {
          //         name: 'BNB',
          //         decimals: 18,
          //         symbol: 'BNB',
          //       },
          //       rpcUrls: ['https://bscscan.com'],
          //     },
          //   ],
          // });
        }
      }
    }
  };

  const onAccountChanged = async (account: string[]) => {
    console.log(account[0], 'account change');

    if (!account.length) {
      onLogout();
      return;
    }

    setAccountInfo(account[0]);
  };

  const getAccount = async () => {
    const accounts = await window.ethereum.request({
      method: 'eth_requestAccounts',
    });

    if (accounts.length && !accountInfo) {
      setAccountInfo(accounts[0]);
      setIsLogin(true);
      navigate('/locker');
      return;
    }
  };
  const bootstrap = async () => {
    const getChainId = await web3.eth.getChainId();
    const isLoginMetamask = JSON.parse(localStorage.getItem('isMetamaskLogin')!);
    if (isLoginMetamask && getChainId === 56) {
      setIsCorrectNetwork(true);
    } else {
      setIsCorrectNetwork(false);
    }

    if (isLoginMetamask) {
      await getAccount();
      navigate('/locker');
      setIsLogin(true);
    } else {
      navigate('/');
      setIsLogin(false);
    }
  };
  useEffect(() => {
    if (window.ethereum) {
      bootstrap();

      window.ethereum.on('accountsChanged', onAccountChanged);
      window.ethereum.on('chainChanged', async (chainId: string) => {
        const isLoginMetamask = localStorage.getItem('isMetamaskLogin')!;
        if (JSON.parse(isLoginMetamask) && chainId !== '0x38') {
          setIsCorrectNetwork(false);
          return;
        }
        setIsCorrectNetwork(true);
        return;
      });
    }
  }, []);

  return (
    <header>
      <section className="header-inner">
        <h1>
          <Link to="/">protocon logo</Link>
        </h1>
        <div>
          {!isLogin ? (
            <a target="_blank" className="btn-round" onClick={onConnectWallet}>
              지갑연결
            </a>
          ) : (
            <a target="_blank" className="btn-round" onClick={onLogout}>
              로그아웃
            </a>
          )}
        </div>
      </section>
      {/*<ToastContainer />*/}
      {/*<MobileModal>*/}
      {/*  <InfoModal closeModal={mobileCloseModal} desc="PC 브라우저에서 이용부탁드립니다." />*/}
      {/*</MobileModal>*/}

      {/*<NotExistMetaMakModal>*/}
      {/*  <InfoModal closeModal={metamaskCloseModal} desc="메타마스크 크롬 익스텐션을 설치 하신 후 이용하세요." />*/}
      {/*</NotExistMetaMakModal>*/}

      {/*<NotChromeModal>*/}
      {/*  <InfoModal closeModal={chromeCloseModal} desc="크롬 브라우저에서 지원하는 서비스입니다." />*/}
      {/*</NotChromeModal>*/}
    </header>
  );
};

export default Header;
