import { Routes, Route } from 'react-router-dom';
import { routes } from '@constants/routes';
import HomePage from '@pages/Home';
import LockerPage from '@pages/Locker';

const PublicRouter = () => {
  const { HOME, LOCKER } = routes;
  return (
    <>
      <Routes>
        <Route path={HOME} element={<HomePage />} />
        <Route path={LOCKER} element={<LockerPage />} />
      </Routes>
    </>
  );
};

export default PublicRouter;
