import { CSSProperties, FC } from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

interface Props {
  closeModal: () => void;
  onClickHandler: () => void;
  amount: string;
  loading: boolean;
}
const override: CSSProperties = {
  display: 'flex',
  margin: '0 auto',
  borderColor: 'red',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};
const ClaimModal: FC<Props> = ({ closeModal, onClickHandler, amount, loading }) => {
  return (
    <div className="popup-wrap" style={{ display: 'block' }}>
      <div className="pop-claim02 pop-claim pop-type01">
        <p style={{ marginBottom: '20px' }}>Claim 하시겠습니까?</p>
        {/*<span>07.15 </span>*/}
        <strong>
          {amount} <i>PEN</i>
        </strong>
        <div className="btn-box" style={{ display: 'flex' }}>
          {loading ? (
            <a className="btn-type01 full">
              <ScaleLoader color="#fff" loading={loading} cssOverride={override} />
            </a>
          ) : (
            <a className="btn-type01 full" onClick={onClickHandler}>
              Claim
            </a>
          )}

          <a className="btn-type01 line" onClick={closeModal}>
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};

export default ClaimModal;
