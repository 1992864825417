import { atom } from 'recoil';

export const isLoginAtom = atom<boolean>({
  key: 'isLogin',
  default: false,
});

export const accountInfoAtom = atom<string>({
  key: 'accountInfoAtom',
  default: '',
});

export const isCorrectNetworkAtom = atom<boolean>({
  key: 'isCorrectNetworkAtom',
  default: false,
});
